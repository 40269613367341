import instance from "../plugins/axios-instance";

const API_URL = "/api/v1/";
class TomsonStockService {
  async getTomsonStocks(month) {
    return await instance(true).get(API_URL + `tomsonstocks/${month}`);
  }

  async save(id, jsonData) {
    if (id === "") {
      return await instance(true).post(API_URL + "tomsonstock", jsonData);
    } else {
      return await instance(true).put(API_URL + `tomsonstock/${id}`, jsonData);
    }
  }

  async getTomsonStock(id) {
    return await instance(true).get(API_URL + `tomsonstock/${id}`);
  }

  async workDay(day) {
    return await instance(true).get(API_URL + `tomsonstock/day/${day}`);
  }

  async delete(id) {
    return await instance(true).delete(API_URL + `tomsonstock/${id}`);
  }
}

export default new TomsonStockService();

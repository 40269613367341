import { createWebHistory, createRouter } from "vue-router";
import Home from "./views/Home.vue";
import NotFound from "@/views/404.vue";
import Login from "@/views/Login.vue";
import Calendar from "@/views/Calendar.vue";
// lazy-loaded
const TomsonStock = () => import("@/components/TomsonStock.vue");
const Profile = () => import("@/views/Profile.vue");
const UserList = () => import("@/views/UserList.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "::: Home 페이지 :::",
    },
  },
  {
    path: "/home",
    component: Home,
    meta: {
      title: "::: Home 페이지 :::",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "::: 로그인 페이지 :::",
      skipAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: "::: 개인 프로필 페이지 :::",
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {
      title: "::: 작업 일지 페이지 :::",
    },
  },
  {
    path: "/tomsonstock",
    name: "tomsonstock",
    component: TomsonStock,
    meta: {
      title: "::: Tomson 포장 재고 :::",
    },
  },
  {
    path: "/404",
    component: NotFound,
    meta: {
      title: "::: 404 Not Found 페이지 :::",
      skipAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/userlist",
    name: "userlist",
    component: UserList,
    meta: {
      title: "::: 사용자 목록 페이지 :::",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  /* 
  URL이동 시 토큰을 이용한 검증. 미 구현.. 보안에 좀 더 치중하고 Back-End 서버에서 검증
  if (to.meta.skipAuth != true) {
    console.log("인증처리");
  } else {
    console.log("미인증");
  } */
  const publicPages = ["/login", "/404"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;

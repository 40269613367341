import instance from "../plugins/axios-instance";

class AuthService {
  login(user) {
    return instance(false)
      .post("/api/auth/signin", {
        id: user.id,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
}
export default new AuthService();

import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 2000, // 2s... default is `0` (no timeout)
});

const DEFAULTS_HEADERS = instance.defaults.headers;

DEFAULTS_HEADERS.post["Content-Type"] = "application/json";
DEFAULTS_HEADERS.put["Content-Type"] = "application/json";

export default function axiosInstance(isAuth) {
  if (isAuth) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      DEFAULTS_HEADERS.common["Authorization"] = `Bearer ${user.access_token}`;
    }
  } else {
    delete DEFAULTS_HEADERS.common["Authorization"];
  }
  return instance;
}

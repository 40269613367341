<template>
  <div id="app">
    <site-header />
    <router-view />
  </div>
</template>

<script>
import SiteHeader from "./components/common/SiteHeader.vue";

export default {
  components: {
    SiteHeader,
  },
};
</script>

import AuthService from "../services/auth.service";
import jwt_decode from "jwt-decode";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };
export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    decodedToken(state) {
      let retValue;
      if (state.status.loggedIn) {
        retValue = jwt_decode(state.user.access_token);
      }
      return retValue;
    },
    id: (state) => (json) => {
      let retValue = false;

      if (state.status.loggedIn) {
        retValue = json.id;
      }

      return retValue;
    },
    isAdmin: (state) => (json) => {
      let retValue = false;

      if (state.status.loggedIn) {
        retValue = json.role === "ROLE_ADMIN";
      }

      return retValue;
    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    verifyToken({ commit }, { status, router }) {
      // 토큰 검증 인증 실패
      if (status === 401) {
        AuthService.logout();
        commit("invalidToken", router);
      } else if (status === 403) {
        // 토큰 검증 인가 실패(이전 페이지로 이동)
        router.go(-1);
      }
    },
    refreshToken({ commit }) {
      //토큰 재발급
      commit("refresh");
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    invalidToken(state, router) {
      state.status.loggedIn = false;
      state.user = null;
      router.push("/login");
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
};

<template>
  <div class="container">
    <h1>📅 작업 일지</h1>
    <Button
      icon="pi pi-bookmark"
      class="p-button-rounded p-button-success"
      @click="onClick('today')"
      v-tooltip.bottom="'오늘'"
    />&nbsp;
    <Button
      icon="pi pi-angle-double-left"
      class="p-button-rounded p-button-warning p-button-text"
      @click="onClick('prev')"
      v-tooltip.bottom="'이전 달'"
    />&nbsp;
    <Button
      icon="pi pi-angle-double-right"
      class="p-button-rounded p-button-warning p-button-text"
      @click="onClick('next')"
      v-tooltip.bottom="'다음 달'"
    />&nbsp;
    <Button
      :label="dateRange"
      class="p-button-secondary p-button-text"
      disabled
    />
    <toast-calendar
      ref="tuiCal"
      :useCreationPopup="useCreationPopup"
      :useDetailPopup="useDetailPopup"
      :view="selectedView"
      :calendars="calendarList"
      :schedules="scheduleList"
      :theme="theme"
      :template="template"
      :taskView="true"
      :scheduleView="true"
      :month="month"
      :week="week"
      :timezones="timezones"
      :disableDblClick="disableDblClick"
      :isReadOnly="isReadOnly"
      @clickSchedule="onClickSchedule"
      @beforeCreateSchedule="onBeforeCreateSchedule"
    />
  </div>
</template>

<script>
import ToastUICalendar from "@/components/ToastUICalendar.vue";
import ToastUITheme from "@/plugins/ToastUITheme";
import TomsonStock from "@/services/tomsonStock.service";
import Button from "primevue/button";

export default {
  name: "CalendarPage",
  components: {
    ToastCalendar: ToastUICalendar,
    Button,
  },
  data() {
    return {
      viewModeOptions: [
        {
          title: "Monthly",
          value: "month",
        },
        {
          title: "Weekly",
          value: "week",
        },
        {
          title: "Daily",
          value: "day",
        },
      ],
      dateRange: "",
      selectedView: "month",
      calendarList: [
        {
          id: "0",
          name: "tomson_work",
          bgColor: "#9e5fff",
          borderColor: "#9e5fff",
        },
        // 작업 스케줄 등록, scheduleList의 calendarId 이용
      ],
      scheduleList: [],
      timezones: [
        {
          timezoneOffset: 540,
          displayLabel: "GMT+09:00",
          tooltip: "Seoul",
        },
      ],
      theme: ToastUITheme,
      template: {
        milestone(schedule) {
          console.log("milestone");
          return `<span style="color:#fff;background-color: ${schedule.bgColor};">${schedule.title}</span>`;
        },
        milestoneTitle() {
          console.log("milestoneTitle");
          return "Milestone";
        },
        allday(schedule) {
          return `${schedule.title}<i class="fa fa-refresh"></i>`;
        },
        alldayTitle() {
          console.log("alldayTitle");
          return "All Day";
        },
      },
      month: {
        startDayOfWeek: 0,
        daynames: ["일", "월", "화", "수", "목", "금", "토"],
      },
      week: {
        showTimezoneCollapseButton: true,
        timezonesCollapsed: true,
      },
      taskView: false,
      scheduleView: true,
      useCreationPopup: false,
      useDetailPopup: false,
      disableDblClick: false,
      isReadOnly: false,
    };
  },
  watch: {
    selectedView(newValue) {
      this.$refs.tuiCal.invoke("changeView", newValue, true);
      this.setRenderRangeText();
    },
  },
  methods: {
    init() {
      this.setRenderRangeText();
    },
    closeMyModal() {
      this.showModalNow = false;
    },
    getWorkData(month) {
      TomsonStock.getTomsonStocks(month).then(
        (response) => {
          // console.log(response.data);
          if (response.data.length > 0) {
            let jsonData = [];

            response.data.forEach((el) => {
              // console.log(el);
              jsonData.push({
                id: el.id,
                calendarId: "0",
                title: "톰슨재고",
                category: "time",
                dueDateClass: "",
                start: new Date(el.work_date).toISOString(),
                isAllDay: true,
              });
            });
            this.scheduleList = jsonData;
          }
        },
        (error) => {
          console.log(error, error.response);
          this.$store.dispatch("auth/verifyToken", {
            status: error.response.status,
            router: this.$router,
          });
        }
      );
    },
    setRenderRangeText() {
      const { invoke } = this.$refs.tuiCal;
      const view = invoke("getViewName");
      const calDate = invoke("getDate");
      const rangeStart = invoke("getDateRangeStart");
      const rangeEnd = invoke("getDateRangeEnd");
      let year = calDate.getFullYear();
      let month = calDate.getMonth() + 1;
      let date = calDate.getDate();
      let dateRangeText = "";
      let endMonth, endDate, start, end;
      switch (view) {
        case "month":
          dateRangeText = `${year}.${month.toString().padStart(2, "0")}`;
          break;
        case "week":
          year = rangeStart.getFullYear();
          month = rangeStart.getMonth() + 1;
          date = rangeStart.getDate();
          endMonth = rangeEnd.getMonth() + 1;
          endDate = rangeEnd.getDate();
          start = `${year}-${month}-${date}`;
          end = `${endMonth}-${endDate}`;
          dateRangeText = `${start} ~ ${end}`;
          break;
        default:
          dateRangeText = `${year}-${month}-${date}`;
      }
      this.dateRange = dateRangeText;
      this.getWorkData(dateRangeText);
    },
    onClick(action) {
      this.$refs.tuiCal.invoke(action);
      this.setRenderRangeText();
    },
    onClickNavi(event) {
      if (event.target.tagName === "BUTTON") {
        const { target } = event;
        let action = target.dataset
          ? target.dataset.action
          : target.getAttribute("data-action");
        action = action.replace("move-", "");
        this.$refs.tuiCal.invoke(action);
        this.setRenderRangeText();
      }
    },
    onClickMore(event) {
      console.log("clickMore", event.date, event.target);
    },
    onClickSchedule(res) {
      // console.group("onClickSchedule");
      // console.log("MouseEvent : ", res.event);
      // console.log("Calendar Info : ", res.calendar);
      // console.log("Schedule Info : ", res.schedule);
      // console.groupEnd();
      // console.log(res.schedule.id);
      this.$router.push({
        name: "tomsonstock",
        query: { id: res.schedule.id },
      });
    },
    onBeforeCreateSchedule(e) {
      // console.log("beforeCreateSchedule", e);
      // console.log("beforeCreateSchedule", new Date(e.start).toISOString());
      this.$router.push({
        name: "tomsonstock",
        query: {
          id: "",
          day: new Date(e.start).toISOString().substring(0, 10),
        },
      });
      // open a creation popup

      // If you dont' want to show any popup, just use `e.guide.clearGuideElement()`

      // then close guide element(blue box from dragging or clicking days)
      e.guide.clearGuideElement();
    },
    onBeforeUpdateSchedule(e) {
      console.log("beforeUpdateSchedule", e);
      e.schedule.start = e.start;
      e.schedule.end = e.end;
      //cal.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
    },
    onClickDayname(res) {
      // view : week, day
      console.group("onClickDayname");
      console.log(res.date);
      console.groupEnd();
    },
    onBeforeDeleteSchedule(res) {
      console.group("onBeforeDeleteSchedule");
      console.log("Schedule Info : ", res.schedule);
      console.groupEnd();
      console.log("beforeDeleteSchedule", res);
      const idx = this.scheduleList.findIndex(
        (item) => item.id === res.schedule.id
      );
      this.scheduleList.splice(idx, 1);
    },
    onAfterRenderSchedule(res) {
      console.group("onAfterRenderSchedule");
      console.log("Schedule Info : ", res.schedule);
      console.groupEnd();
    },
    onClickTimezonesCollapseBtn(timezonesCollapsed) {
      // view : week, day
      console.group("onClickTimezonesCollapseBtn");
      console.log("Is Collapsed Timezone? ", timezonesCollapsed);
      console.groupEnd();
      if (timezonesCollapsed) {
        this.theme["week.timegridLeft.width"] = "100px";
        this.theme["week.daygridLeft.width"] = "100px";
      } else {
        this.theme["week.timegridLeft.width"] = "50px";
        this.theme["week.daygridLeft.width"] = "50px";
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style scoped>
@import "tui-calendar/dist/tui-calendar.css";
@import "@/plugins/ToastUITheme.css";
</style>

<template>
  <Menubar v-if="loggedIn" :model="items">
    <template #start>
      <Button
        icon="pi pi-fw pi-home"
        class="p-button-rounded p-button-text"
        v-tooltip.bottom="'Home'"
        @click="$router.push('/home')"
    /></template>
    <template #end>
      <Button
        icon="pi pi-fw pi-power-off"
        class="p-button-rounded p-button-help p-button-text"
        v-tooltip.bottom="'로그아웃'"
        @click="logout"
    /></template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";
import Button from "primevue/button";

export default {
  name: "'SiteHeader",
  components: {
    Menubar,
    Button,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getToken() {
      return this.$store.getters["auth/decodedToken"];
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"](this.getToken);
    },
  },
  data() {
    return {
      isAuth: false,
      items: [
        {
          label: "작업일지",
          icon: "pi pi-fw pi-calendar",
          command: () => {
            this.$router.push("/calendar");
          },
        },
        {
          label: "계정",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "프로필",
              icon: "pi pi-fw pi-user-edit",
              command: () => {
                this.$router.push("/profile");
              },
            },
            {
              separator: true,
            },
            {
              label: "사용자 목록",
              icon: "pi pi-fw pi-users",
              visible: () => this.isAdmin,
              command: () => {
                this.$router.push("/userlist");
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.header {
  position: fixed;
  top: 0;
  /* width: 100% */
  left: 0;
  right: 0;
}
</style>

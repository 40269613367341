import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import PrimeVue from "primevue/config";
import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Tooltip from "primevue/tooltip";

const COOKIE_SECURE = process.env.VUE_APP_COOKIE_SECURE;

createApp(App)
  .use(router)
  .use(store)
  .use(VueCookies, {
    // expireTimes: "30d",
    secure: COOKIE_SECURE,
    // httponly 설정은 서버에서 응답으로만 가능
  })
  .use(BootstrapIconsPlugin)
  .use(PrimeVue, {
    // 다국어 설정  https://www.primefaces.org/primevue/locale
    locale: {
      weak: "약함",
      medium: "중간",
      strong: "강함",
    },
  })
  .directive("tooltip", Tooltip)
  .mount("#app");

<template>
  <br /><br /><br /><br />
  <main class="form-signin">
    <!-- <form @submit.prevent="onSubmit(id, password)"> -->
    <form @submit.prevent="handleLogin(id, password)">
      <h1 class="h3 mb-3 fw-normal">Please Log in</h1>
      <div class="form-group">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="ID"
            required
            autofocus
            v-model="id"
            ref="id"
          />
          <label for="floatingInput">아이디</label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Password"
            required
            v-model="password"
          />
          <label for="floatingPassword">비밀번호</label>
        </div>
      </div>
      <div class="form-group">
        <div class="checkbox mb-3">
          <label>
            <input type="checkbox" v-model="checkRemember" /> Remember me
          </label>
        </div>
      </div>
      <div class="form-group">
        <button class="w-100 btn btn-lg btn-primary" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Login</span>
        </button>
      </div>
      <div class="form-group">
        <div v-if="error" class="alert alert-danger" role="alert">
          {{ error }}
        </div>
      </div>
      <p class="mt-5 mb-3 text-muted">Copyright &copy; 2022 SoftNara</p>
    </form>
  </main>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      content: "로그인 페이지",
      id: "",
      password: "",
      checkRemember: "false",
      error: "",
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/calendar");
    } else {
      if (this.$cookies.isKey("id")) {
        this.id = this.$cookies.get("id");
        this.checkRemember = true;
      }
    }
  },
  methods: {
    onSubmit(id, password) {
      console.log("id[%s], password[%s]", id, password);
      if (this.checkRemember) {
        this.$cookies.set("id", id, Infinity); // never expire
      } else if (this.$cookies.isKey("id")) {
        this.$cookies.remove("id");
      }
    },
    handleLogin(id, password) {
      let user = new Array();
      user.id = id;
      user.password = password;

      this.error = "";
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          if (this.checkRemember) {
            this.$cookies.set("id", id, Infinity); // never expire
          } else if (this.$cookies.isKey("id")) {
            this.$cookies.remove("id");
          }
          this.$router.push("/calendar");
        },
        (error) => {
          if (error.response) {
            let e = error.response.data;
            console.log(
              "errorcode[%s], errormessage[%s]",
              e.error_code,
              e.error_message
            );

            if (error.response.status === 401) {
              this.error = "아이디 또는 비밀번호가 올바르지 않습니다.";
              this.id = "";
              this.password = "";
              this.$refs.id.focus();
            } else {
              this.error = e.error_message;
            }
          } else {
            console.log(error);
            this.error =
              "서버 접속에 문제가 발생했습니다. 재시도 후 문제 발생 시 관리자에게 문의 바랍니다.";
          }
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
